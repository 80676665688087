<template>
  <div class="container" v-loading="loading">
    <div class="row">
      <div class="col-xs-3 col-sm-3 col-md-3">
      <userSiderbar />
    </div>

    <div class="col-xs-9 col-sm-9 col-md-9">
      <form @submit.prevent="handleSubmit">
        <div class="form-group row">
          <div class="col-sm-12">
            <el-input type="password" v-model="oldPassword" placeholder="旧密码" required show-password></el-input>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <el-input type="password" v-model="newPassword" placeholder="新密码" minlength="6" required show-password></el-input>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <el-input type="password" v-model="confirmNewPassword" placeholder="确认新密码" minlength="6" required show-password></el-input>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12">
            <button type="submit" class="btn btn-success right">提交</button>
          </div>
        </div>
      </form>
      
    </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import userSiderbar from "@/views/user/user-sidebar.vue";
import { ElMessage } from 'element-plus'

export default {
  components: {
    userSiderbar,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      loading: false,
    };
  },
  async created() {
    if (localStorage.getItem("username") != "null") {
      this.username = localStorage.getItem("username");
    }
    if (localStorage.getItem("userDescription") != "null") {
      this.userDescription = localStorage.getItem("userDescription");
    }
    // this.loading = false
  },
  methods: {
    tigs(msg, type) {
      ElMessage({
        message: msg,
        type: type,
      })
    },
    async handleSubmit() {
      const response = await axios.post("/api/web/auth/userChangePassword", {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        confirmNewPassword: this.confirmNewPassword,
      });

      // console.log(response);
      if (response.data.code == 200) {
        this.tigs(response.data.message, 'success')
      } else {
        this.tigs(response.data.message, 'warning')
      }
    },
  },
};
</script>
